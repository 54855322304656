@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../themes/3skye.scss";

#master-grid {
    &.dark-theme {
        background-color: $colour-background;
        // transition: 300ms cubic-bezier(0, 0.46, 1, 0.49);
        transition: all 0.3s ease-in-out;
    } 
    &.light-theme {
        background-color: $light-colour-background;
        // transition: 300ms cubic-bezier(0, 0.46, 1, 0.49);
        transition: all 0.3s ease-in-out;
    }
}

@media screen and (max-width: $breakpoint-md) {
    input, textarea {
        font-size: 16px !important;
    }
}