@import "./LayoutVariables.scss";

// MIXINS
@mixin VerticalScroll {
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

// STYLES
:root {
	// Base for font size + 8 point grid.
	font-size: 16px;
}

// MASTER GRID(PAGE GRID)
#master-grid {
	font-size: 16px;
	height: 100vh;
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-rows: 64px auto 1fr;
	grid-template-columns: $menu-side-nav calc(100vw - 128px) $menu-side-nav;
	grid-template-areas: 
		"top-nav-banner top-nav-banner top-nav-banner"
		"side-nav-bar page-tabs-banner side-nav-right"
		"side-nav-bar content-grid side-nav-right";

	

	&.side-nav-right-extensions {
		grid-template-columns: $menu-side-nav calc(100vw - 128px - 344px) $sidebar-width $menu-side-nav;
		grid-template-areas: 
			"top-nav-banner top-nav-banner top-nav-banner top-nav-banner"
			"side-nav-bar page-tabs-banner side-nav-right-extension side-nav-right"
			"side-nav-bar content-grid side-nav-right-extension side-nav-right";
			&.right-extension-expanded {
				grid-template-columns: $menu-side-nav calc(100vw - 128px - 472px) $sidebar-right-expanded $menu-side-nav;
			}
			&.right-extension-expanded-full {
				grid-template-columns: $menu-side-nav calc(100vw - 128px - 1200px) $sidebar-right-expanded-full $menu-side-nav;
			}
	}

	&.side-nav-left-extensions {
		grid-template-columns: $menu-side-nav $sidebar-width calc(100vw - 128px - 344px) $menu-side-nav;
		grid-template-areas: 
			"top-nav-banner top-nav-banner top-nav-banner top-nav-banner"
			"side-nav-bar page-tabs-banner page-tabs-banner side-nav-right"
			"side-nav-bar side-nav-left-extension content-grid side-nav-right";
	}

	&.side-nav-left-right-extensions {
		grid-template-columns: $menu-side-nav $sidebar-width calc(100vw - 128px - 344px - 344px) $sidebar-width $menu-side-nav;
		grid-template-areas: 
			"top-nav-banner top-nav-banner top-nav-banner top-nav-banner top-nav-banner"
			"side-nav-bar page-tabs-banner page-tabs-banner side-nav-right-extension side-nav-right"
			"side-nav-bar side-nav-left-extension content-grid side-nav-right-extension side-nav-right";
			&.right-extension-expanded {
				grid-template-columns: $menu-side-nav $sidebar-width calc(100vw - 128px - 344px - 472px) $sidebar-right-expanded $menu-side-nav;
			}
			&.right-extension-expanded-full {
				grid-template-columns: $menu-side-nav $sidebar-width calc(100vw - 128px - 344px - 1200px) $sidebar-right-expanded-full $menu-side-nav;
			}
	}
	
}

#content-grid {
	grid-area: content-grid;
	padding: $content-top-margin $content-gutter-margin;
	@include VerticalScroll();
	width: 100%;
	
	.content-inner {
		max-width: 1400px;
		overflow-x: hidden;
		min-height: 100%;
		position: relative;
		min-width: 1000px;

		&.half-width {
			max-width: 528px;
		}

		&.two-thirds-width {
			max-width: 704px;
			min-width: 704px;
		}
	}
	
	.overflowVisible {
		overflow: visible !important;
	}

	& > div {
		// Use this to easily check container bounds
		// border: 2px dashed #faa4c0;
	}
}

// INFO BANNER
#info-banner {
	position: fixed;
	width: 100vw;
	height: 56px;
	bottom: 0;
	left: 0;
	background-color: #faa4c0;
	display: none;
	padding: 1rem $page-x-margin;
	align-items: center;

	.info-banner-inner {
		text-align: center;
		width: 100%;
		border: 1px dashed #fff;
	}

	&.display-info-banner {
		display: flex;
	}
}

// TOASTS
#toaster {
	position: fixed;
	right: $page-x-margin;
	top: 96px;

	.toast {
		display: none;
		min-width: 248px;
		min-height: 48px;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		background-color: #faa4c0;
		border: 1px dashed #fff;
		box-shadow: 0 0 5px rgba(#000, 0.1);
	}

	&.display-toasts {
		.toast {
			display: flex;
		}
	}
}

// Mobile styles
@media screen and (max-width: $breakpoint-mobile) {
	#master-grid {
		display: block;
		height: auto;
		width: 100%;
	}
	 
	#content-grid {
		display: none;
	}
	
}