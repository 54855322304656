@import "@threeskye/core-components/dist/styles/Variables.scss";

.addable-fields-wrapper {
	background-color: $colour-form-input;
	border-radius: 6px;
	transition: 200ms;
	padding: 3px 8px;

	.token-field__menu-notice--no-options {
		background-color: rgba($colour-primary, 0.2);
		
		.create-suggestion {
			color: $colour-primary;
		}
	}

	.single-field-wrapper {
		padding: 8px 0;
		border-bottom: 1px solid rgba(#fff, 0.3);

		&:last-of-type {
			border-bottom: none
		}
		
		input, textarea, .dropdown-value-wrapper {	
			background-color: transparent;
			border: none;
			margin: 0;
			padding: 0 2px;
		}

		.token-field {
			margin: 0;
		}

		.token-field__control {
			min-height: auto;
		}

		.token-field__value-container {
			padding: 0 !important;

			.suggestion-text {
				color: #fff;
			}
		}

		.token-field__indicator {
			padding: 0 4px !important;
		}

		.token-field .token-field__control {
			background-color: transparent;
			color: #fff !important;	
		}

		.token-field__value-container--has-value {
			color: #fff;
		}

		.token-field__placeholder {
			color: #fff !important;
			opacity: 0.2;
		}

		.token-field__control--is-focused {
			margin: 0;
			
			.token-field__placeholder {
				color: rgba($colour-form-focus-text, 1) !important;
			}
		}

		.dropdown-value-wrapper {
			outline: none;
		}

	}

	.addable-location-field {
		padding-top: 8px;
	}

	.overseas-toggle-wrapper {
		padding-top: 0;
	}

	.overseas-fields-container {
		label {
			display: none;
		}

		.form-row {
			margin: 0;

			.form-col-grow {
				&:last-child {
					border-left: 1px solid rgba(#fff, 0.3);
				}

				&:only-child {
					border: none;
				}
			}
		}
	}

	&:focus-within {
		background-color: #fff;

		.dropdown-value-wrapper, .dropdown-value-wrapper i {
			color: $colour-form-focus-text !important;

			&:hover {
				color: $colour-form-focus-text;
			}
		}

		.dropdown-selection-list {
			color: $colour-form-focus-text;
			background-color: #fff;
			box-shadow: 0px 6px 12px rgba(#000, 0.2);
			border: 1px solid rgba($colour-secondary, 0.06);

			li {
				color: $colour-primary;
				border-left: rgba($colour-secondary, 0.06);
				border-right: rgba($colour-secondary, 0.06);

				&:hover {
					background-color: rgba($colour-secondary, 0.06) !important;

				}
			}
		}

		label {
			color: $colour-form-focus-text;
		}

		.token-field__value-container--has-value {
			color: $colour-form-focus-text;

			.suggestion-text {
				color: $colour-form-focus-text;
			}
		}

		.token-field__placeholder {
			color: rgba($colour-form-focus-text, 0.6) !important;
		}

		input, textarea, .token-field__single-value {
			color: $colour-form-focus-text !important;
			
			&::placeholder {
				color: $colour-form-focus-text;
			}
		}

		.single-field-wrapper {
			border-color: rgba(#30395A, 0.2);

			&:last-of-type {
				border-bottom: none
			}
		}

		.form-row {
			.form-col-grow {
				&:last-child {
					border-color: rgba(#30395A, 0.2);
				}

				&:only-child {
					border: none;
				}
			}
		}
	}
}