@import "@threeskye/core-components/dist/styles/Variables.scss";

.ets-tooltip {
	.tooltip-popup-wrapper {
		padding: 0;
	}
}

.ets-wrapper-popup {
	position: absolute;
    right: 5.5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.ets-template-wrapper-popup {
    padding-right: 5.5px;
    margin-left: auto;
    max-width: 50px;
} 

.ets-inner {
	padding: 0 4px;
    width: 40px;
    height: 24px;
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 5px;
	background-color: $colour-primary;
	position: relative;
	cursor: pointer;

	.type-icon {
		font-size: 19px;
	}

	&:hover {
		opacity: 0.8;
	}

	&.ets-type-email {
		background-color: #5985FC;
	}

	&.ets-type-email-template {
		background-color: #208BA5;
	}

	&.ets-type-phone-call {
		background-color: $colour-success;
	}
	&.ets-type-file-note {
		background-color: $colour-third-party;
	}
	&.ets-type-meeting {
		background-color: #9898CB;
	}
	&.ets-type-workspace-note {
		background-color: #FAC35E;
	}
	&.ets-type-reminder {
		background-color: $colour-primary;
	}

	.expand-icon {
		transform: translateX(-4px);
		font-size: 14px;
	}
}

.event-type-options {
	.type-option {
		width: 112px;
		padding: 12px 8px;
		cursor: pointer;

		&:first-of-type {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		&:last-of-type {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&:hover {
			background-color: rgba(#424E7B, 0.8);
		}

		&.active {
			background-color: #424E7B;
		}
	}
}