@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../../styles/Mixins.scss";

.tool-bar-divider {
	width: 1px;
	height: 24px;
	background-color: rgba(#fff, 0.2);
}

.slate-editor-inner {
	// Styles for the editor text box
	div[role=textbox] {
		@include input-styling();
		margin-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		min-height: 400px !important;
		font-size: 0.9rem;
	}

	span[data-slate-placeholder="true"] {
		opacity: 0.2 !important;
		top: 9px
	}

	&:focus-within {
		.editor-toolbar {
			opacity: 1;
		}
	}

	.editor-wrapper {
		font-size: 0.9rem;
		h1 {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 6px;
		}

		h2 {
			font-size: 1.2rem;
			font-weight: 600;
			margin-bottom: 6px;
		}

		ol, ul {
			padding-left: 1.6rem;

			li {
				margin-bottom: 0.5rem;
				padding-left: 4px;
			}
		}

		ul {
			list-style-type: disc;
		}

		p {
			font-size: 0.9rem;
		}
	}
}

.editor-toolbar {
	background-color: #373F5E;
	background-color: #3f486a;
	width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	gap: 6px;
	align-items: center;
	padding: 8px 6px;
	opacity: 0.5;
	flex-wrap: wrap;
}

.toolbar-button {
	height: 26px;
	width: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: rgba(#fff, 0);
	border-radius: 2px;
	transition: 100ms ease;
	
	&:hover {
		border-radius: 4px;
		background-color: rgba(#fff, 0.05);
	}

	i {
		font-size: 20px;
		color: #fff;
		opacity: 0.7;
	}

	&.toolbar-button__active {
		background-color: rgba(#fff, 0.15);
		border-radius: 4px;

		&:hover {
			border-radius: 4px;
		}

		i {
			opacity: 1;
		}
	}
}