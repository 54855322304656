@import "@threeskye/core-components/dist/styles/Variables.scss";

.etf-outer-wrapper {
	width: 100%;

	.token-field {
		.token-field__indicators {
			   height: auto;
		   }
	}
	
	.addable-fields-wrapper {
		padding-bottom: 4px;
	}

	.addable-fields-wrapper .single-field-wrapper .token-field .token-field__control {
		padding: 8px 0px;
    	cursor: text;
	}

	.single-field-wrapper {
		padding: 0;
	}

	.cc-wrapper {
		position: relative;
		padding-right: 28px;
	
		.bcc-button {
			font-size: 0.9rem;
			color: $colour-text;
			font-weight: 500;
			opacity: 0.2;
			cursor: pointer;
			position: absolute;
			right: 0;
			bottom: 6px;
			background-color: rgba(#fff, 0.3);
			border-radius: 4px;
			padding: 2px;
	
			&:hover {
				color: #fff;
				opacity: 0.7;
			}
		}
	
		&.bcc-active {
			padding-right: 0;
		}
	}

	&:focus-within {
		.bcc-button {
			opacity: 0.3;
			color: $colour-form-focus-text;
			background-color: rgba($colour-form-focus-text, 0.15);

			&:hover {
				color: rgba($colour-primary, 0.7);
				background-color: rgba($colour-primary, 0.2);
				opacity: 1;
			}
		}
	}
}