@import "../../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../../themes/ColoursRedo.scss";

.extension-empty-state-message {

	i {
		font-size: 28px;
		margin-bottom: 4px;
	}

	h6 {
		font-weight: 500;
		font-size: 16px;
		margin: 0;
	}

	p {
		margin: 0;
		font-size: 14px;
		opacity: 0.7;
		text-align: center;
		line-height: 1;
	}

	&.clickable-message {
		p {
			text-decoration: underline;
			cursor: pointer;

			&:hover {
				color: $colour-primary;
				opacity: 1;
			}
		}
	}
}