.tooltip-popup-wrapper {
	padding: 12px;
	font-size: 14px;

	&.padding-xxs {
		padding: 0px
	}

	&.padding-xs {
		padding: 4px;
	}

	&.padding-xs-s {
		padding: 7px;
	}

	&.padding-s {
		padding: 10px;
	}

	&.padding-l {
		padding: 10px;
	}

	&.padding-xl {
		padding: 10px;
	}

	&.size-s {
		font-size: 12px;
	}

	&.size-l {
		font-size: 16px;
	}
}


.tooltip-with-icon {
	display: flex;
	align-items: center;

	svg {
		width: 16px;
		margin-right: 8px;
		color: #fff;
	}
}

.tag-group {
	padding: 10px;
	cursor: pointer;

	&:hover {
		opacity: 0.5;

		&:first-of-type {
			border-top-right-radius: 8px;
		}

		&:last-of-type {
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
		}
	}
}