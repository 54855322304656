@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

.status-tag {
	display: inline-block;
	padding: 3px 10px;
	border-radius: 20px;
	background-color: $colour-success;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;

	&.open, &.completed {
		background-color: $colour-success;
	}

	&.inactive, &.rejected {
		background-color: $colour-warning;
	}

	&.cancelled {
		background-color: #424E7B;
	}

	&.closed {
		background-color: #E35953;
	}

	&.prospect {
		background-color: $colour-warning;
	}
}