@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../../themes/ColoursRedo.scss";

// ============================
// *** DARK THEME ***
// ============================
.dark-theme {
	// @media screen and (min-width: $breakpoint-md){
		#side-nav {
			background-color: $colour-dark;
		}
	// }
}

// ============================
// *** LIGHT THEME ***
// ============================
.light-theme {
	// @media screen and (min-width: $breakpoint-md){
		#side-nav {
			background-color: $colour-light-light;
		}
	// }
}

#side-nav-bar {
	&.right {
		z-index: 90;
	}
}

// ============================
// *** STYLES ***
// ============================

.active {
	opacity: 1 !important;
	background-color: rgba($colour-secondary, 0.4);

	&::after {
		content: "";
		height: 100%;
		width: 3px;
		background-color: $colour-primary;
		position: absolute;
		top: 0;
		right: -20px;
		border-radius: 10px;
	}
}

#side-nav-links, #side-nav-footer {
	ul {
		list-style-type: none;
	}

	i {
		font-size: 1.5rem;
		color: $colour-primary;
	}
}

#side-nav-links {
	width: 100%;
	text-align: center;
	border: none;
	margin: 0;

	li {
		cursor: pointer;
		padding: 0;
		text-align: center;
		display: block;
		opacity: 0.5;
		transition-duration: 300ms;
		position: relative;
		margin-bottom: 2rem;
		height: 24px;

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			opacity: 1;
		}
	}

	.active {
		background-color: transparent;
	}


	&.side-nav-links-right {
		.active {
			&::after {
				display: none;
			}
		}
		.tooltip-container {
			&.sidebar-link {
				&:hover {
					.tooltip-popup {
						right: 50%;
						left: unset;
					}
				}
		
				.tooltip-popup {
					right: 46%;
					left: unset;
				}
			}
		}
	}
}

#side-nav-footer {
	margin-top: 2.5rem;

	i {
		color: $colour-warning;
	}

	li {
		padding: 0;
		display: block;
		cursor: pointer;
	}
}
