@import 'themes/3skye';
@import "@threeskye/core-components/dist/styles/Variables.scss";

#welcome-message {
	margin-bottom: 1.4rem;
	font-size: 1.1rem;
	text-align: center;

	p {
		font-weight: 300;
	}
}

.login-form {
	button {
		margin: 2em 0;
		width: 100%;
	}
}

.pseudo-link {
	cursor: pointer;

	&:hover {
		color: $colour-primary;
	}
}

.signup-btn {
	display: none;
}

.loading-button{
	pointer-events: none;

	svg {
		margin-right: 10px;
	}

	.threeskye-loading-icon-outer-ring, .threeskye-loading-icon-inner-ring {
		fill: #fff !important;
	}
}

.login-button-row {
	flex-direction: column-reverse;
}

.forgot-pw-link {
	margin-top: 15px !important;
}

#form-info {
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
}

#footer-text {
	align-self: flex-end;
	text-align: center;
	margin-top: 1rem;

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid $colour-primary;
		padding-bottom: 1px;

	}
	p {
		font-weight: normal;
		font-size: 0.8rem;
	}
}

#cookie-notice {
	position: fixed;
	text-align: center;
	bottom: 0;
	left: 0;
	right: 0;
	padding:0.5em 1rem;
	color: #cbd0e2;
	background-color: $colour-secondary;
	z-index: $layer-10;
	box-shadow: 0 0 7px #00000035;

	a {
		color: white;
		text-decoration: none;
		font-weight: 600;
	}
	p {
		font-weight: normal;
		font-size: 0.8rem;
	}
}

//for text msg verification code
.validation-code{
	width: 100%;
	display: flex;
	justify-content: space-between;

	input{
		margin: 0.4rem 3px !important;
		margin-bottom: 7px !important;
		font-size:16px !important;
		padding: 0.2em 0 !important;
		text-align: center !important;
		height: 40px
	}
	
	.twofa-code-input{
		&:focus::placeholder{
			color: transparent;
		}
	}
}


#master-container {
	padding: 1.8rem;
	min-height: 100vh;
	display: grid;
	grid-template-columns: minmax(auto, 400px);
	justify-content: center;
}

.logo-container {
	width: 80px;
}

.username-wrapper {
	margin-bottom: 20px;
	width: 100%;

	span {
		color: $colour-primary;
	}
}

// Some validation styles. Could be moved to core-components and merged with the validation stuff from crm.
.invalid-field {
	input, textarea {
		border-color: $colour-warning !important;
		background-color: rgba($colour-warning, 0.3) !important;

		&:focus {
			color: #fff !important;
		}

		&::placeholder {
			color: #fff !important;
			opacity: 0.4;
		}
	}

	.date-field-outer {
		border-color: $colour-warning !important;
		background-color: rgba($colour-warning, 0.3) !important;

		input {
			background-color: transparent !important;
		}
	}
	.invalid-field-icon{
		font-size: inherit;
		margin-right: 5px;
	}
	.invalid-field-message {
		color: $colour-warning !important;
		height: auto;
		opacity: 1;
		transition: 200ms ease-in;
		overflow: hidden;
		display: flex;
	}
}

.valid-field {
	
	.invalid-field-message {
		overflow: hidden;
		height: 0;
		opacity: 0;
	}
}

// Move to core components
.justify-center {
	justify-content: center;
}

.text-align-center {
	text-align: center;
}

// Media Queries
@media (min-width: $breakpoint-md) {
	.login-button-row {
		flex-direction: row;
	}

	.forgot-pw-link {
		margin-top: 0 !important;
		display: flex;
		align-items: center;
	}	
}

// Medium/Large screens
@media (min-width: 900px) {
	.logo-container{
		width: 130px;
	}

	#master-container {
		grid-template-columns: 1fr 1fr minmax(400px, 480px) 1fr 1fr;
		grid-template-rows: 1fr auto;
		justify-items: center;
	}

	#header {
		grid-column: 1/2;
		padding: 1.5rem;
	}

	#content {
		grid-column: 3/4;
		align-self: center;
		width: 100%;
	}

	#footer-text {
		max-height: 100px;
		grid-column: span 6;
		align-self: center;
		display: none;
	}

	.login-form {

		.pseudo-form {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		button {
			width: 45%;
		}
	}

	.signup-btn {
		display: inline-block;
	}
}

// Extra large screens
@media (min-width: 1600px) {
	.logo-container {
		width: 190px;
	}

	#master-container {
		grid-template-columns: 1fr 1fr minmax(400px, 600px) 1fr 1fr;
	}
}

// Landscape mobile devices
@media (max-height: 500px){
	#footer-text {
		padding-top: 2rem;
	}
}


// ======================= From original 3skye theme =================================

@import "themes/3skye";

#welcome-message {
	h1 {
		color: $colour-primary;
	}
}