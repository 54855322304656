@import "@threeskye/core-components/dist/styles/Base.scss";
@import "./themes/3skye-css.scss";
@import "./layout/LayoutVariables.scss";
@import "./styles/common.scss";

/* Tabular fonts */
@font-face {
	font-family: 'Figtree';
	src: url('./fonts/tabular/Figtree-Regular.woff') format('woff2'),
		url('./fonts/tabular/Figtree-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Figtree';
	src: url('./fonts/tabular/Figtree-Bold.woff') format('woff2'),
		url('./fonts/tabular/Figtree-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Figtree';
	src: url('./fonts/tabular/Figtree-Medium.woff') format('woff2'),
		url('./fonts/tabular/Figtree-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

.tabular-nums {
	font-family: 'Figtree';
	font-variant: tabular-nums;
	font-weight: inherit;
	// color: yellow;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.cursor-default {
	cursor: default;
}

.sub-label-header {
	font-size: 12px;
	color: #fff;
	opacity: 0.5;
	font-weight: 600;
}

.page-content-header {
	font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
}

.chart-sub-label {
	font-size: 10px;
    color: #fff;
    opacity: 0.4;
}

.d-none-mobile {
	@media screen and (max-width: $breakpoint-mobile) {	
		display: none !important;
	}
}