@import "@threeskye/core-components/dist/styles/Variables.scss";

.token-field {
    .create-label {
        color: black;
    }
    .create-label-text {
        color: black;
        font-weight: 600;
    }
    .suggestion-text {
        color: #fff;
    }
    .location-field-locator {
        font-size: 1.1rem;
        margin-left: 6px;
        margin-right: 6px;
        color: $colour-form-text;
        cursor: pointer;
    }

    .token-field__control--is-focused {
        .suggestion-text {
            color: $colour-form-focus-text;
        }

        .location-field-locator {
            color: $colour-primary;
        }
        
    }

    .token-field__menu {
        .suggestion-text {
            color: $colour-primary;
        }
    }

    &:focus-within {
        .token-field__single-value {
            background-color: rgba($colour-primary, 0.4);
        }
    }
}

.location-expanded {
    margin-top: 20px;

    input:read-only {
        background-color: transparent;
        border: none;
        font-size: 1.1rem;
        color: #fff;
        width: 100%;
        padding: 0;
        margin-top: 0;
    }

    .event-modal-field {
        margin-top: 10px;
    }
}

.location-expanded-loading {
    margin: 15px auto 0px auto;
    width: 30px;
}

.location-mapid {
    width: 100%;
    height: 160px;
}