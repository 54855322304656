@import "@threeskye/core-components/dist/styles/Variables.scss";

.in-page-link {
	display: block;
	position: sticky;
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	&:hover {
		opacity: 1;
		text-decoration: none;
		color: #fff;
		.visible-link {
			text-decoration: none;
		}
 	}
  	.visible-link {
		margin-left: 5px;
	  	text-decoration: underline;
	}
}

 