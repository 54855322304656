@import "@threeskye/core-components/dist/styles/Variables.scss";

.minimised-tab {
	background-color: $colour-primary;
	text-align: center;
	font-size: 1rem;
	position: fixed;
	right: 0;
	top: 110px;
	display: inline-block;
	padding: 0.9em 1rem;
	border-radius: 6px 0 0 6px;
	cursor: pointer;
	@include boxShadow(0.2);
	transition: ease-in-out 100ms;
	z-index: $layer-10;
	display: none;

	&:hover {
		padding-right: 1.5rem;
	}

	@media screen and (max-width: $breakpoint-md) {
		display: none;
	}
}
