@import "@threeskye/core-components/dist/styles/Variables.scss";

.modal-uploads-container {
	li {
		width: 100%;
		background-color: rgba($colour-secondary, 0.30);
		display: flex;
		flex-wrap: wrap;
		border-radius: 6px;
		align-items: center;
		justify-content: space-between;
		padding: 0.4em 0.5rem;
		margin-bottom: 0.5rem;
		position: relative;

		.file-icon-container {
			width: 18px;
		}
	}
	
}

.modal-upload-name {
	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	order: 1;
	margin-bottom: 5px;
}

.modal-upload-size {
	opacity: 0.3;
	width: 10%;
	order: 2;
	margin-bottom: 5px;
    margin-right: auto;
    overflow: ellipsis;
    white-space: nowrap;
}

.modal-upload-bar-outer {
	width: 80%;
	background-color: rgba($colour-secondary, 0.4);
	height: 10px;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	order: 3
}

.modal-upload-bar-inner {
	width: 40%;
	height: 100%;
	background-color: $colour-primary;
	left: 0;
	top: 0;
}

.modal-upload-cancel {
	color: #fff;
	font-size: 1.4rem;
	cursor: pointer;
	opacity: 0.3;
	order: 4;

	&:hover {
		opacity: 1;
	}
}



// @media (min-width: $breakpoint-md) {
	.modal-uploads-container {
		li {
			flex-wrap: nowrap;
		}	
	}

	.modal-upload-name {
		max-width: 40%;
		margin-bottom: 0;
	}

	.modal-upload-size {
		margin-right: 10px;
		margin-bottom: 0;
		margin-right: auto;
	}

	.modal-upload-bar-outer {
		width: 35%;
		margin-left: auto;
		margin-right: 20px;
	}
	
// }