@import "../../themes/3skye.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

// Move to core components
@mixin inputResetStyle {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
}

.time-field-outer {
    display: flex;
    align-items: center;
    position: relative;
    background-color: $colour-secondary;
	border: none;
	border-radius: 5px;
    width: 100%;
    padding: 0.6em 0.8rem;
    margin: 0.4rem 0;
    min-width: 105px;
    gap: 6px;
    height: 36px;

    img {
        width: 14px;
        cursor: pointer;
    }

    i {
        font-size: 1rem;
        margin-right: 0;    
    }

    input {
        @include inputResetStyle();
        border-radius: 0;
    }

    &:focus-within {
        background-color: #fff;
        color: $colour-form-focus-text;

        i {
            color: $colour-primary;
        }

        input {
            color: $colour-form-focus-text;

            &::placeholder {
                color: $colour-form-focus-text;
            }
        }
    }
}

.time-arrows-container {
    display: flex;
    position: absolute;
    left: 80px;
    height: 36px;
    width: 24px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    i {
        cursor: pointer;
        opacity: 0.3;

        &:hover {
            opacity: 1;
        }
    }
}

.time-arrow-up {
    transform: translateY(1px) scale(1.2);
}

.time-arrow-down {
    transform: translateY(-1px) scale(1.2);
}

.time-field-field {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 0.9rem;
    color: #fff;
    width: 64px;
}

.datetime-now-button {
    margin-top: 20px;
    font-size: 14px;

    span {
        margin-right: 10px;
    }
}

// @media (min-width: 600px) {
    .date-time-input-container {
        .time-field-outer {
            width: 30%;
            min-width: 110px;
        }
    }

    .datetime-now-button {
        margin-top: 0;
    }
// }

@media (max-width: $breakpoint-md) {
    .time-field-field, .date-field-field {
        font-size: 1rem !important;
    }

    .date-time-input-container {
        .time-field-outer {
            width: 70%;
        }
    }

    .time-arrows-container {
        display: flex;
    }   
}