@import "./3skye.scss";

.date-field-outer {
    background-color: $colour-secondary;
    position: relative;

    &:focus-within {
        background-color: #fff;
        color: $colour-form-focus-text;

        i {
            color: $colour-primary;
        }

        input {
            color: $colour-form-focus-text;

            &::placeholder {
                color: $colour-form-focus-text;
            }
        }
    }

    .calendar-wrapper-showing {
        transform: translateY(106%);
        left: -1px;
    }

}

.react-calendar {
    &, & button {
        background-color: $colour-secondary !important;
        color: #fff;
    }
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #fff !important;
    opacity: 0.2 !important;
}

.react-calendar__tile:enabled:focus, .react-calendar__tile:enabled:hover,
.react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:enabled:hover {
    background-color: rgba(#fff, 0.2) !important;
}

.react-calendar__month-view__days__day--weekend {
    color: rgba(#fff, 0.7) !important;
}

.react-calendar__month-view__weekdays__weekday {
    text-align: center;
}

.react-calendar__navigation__label {
    color: #fff !important;

    &:hover {
        background-color: rgba(#fff, 0.3) !important;
    }
}

.react-calendar__tile--hasActive {
    background-color: $colour-primary !important;
}

// Styles for the date input fields
.date-field-field {
    color: #fff;

    &:focus {
        .date-field-outer{
            background-color: #fff;
        }
    }
}
