@import "../../themes/3skye.scss";

// MIXINS
@mixin tagLabel {
	font-size: 0.8rem;
	opacity: 0.7;
	margin-left: 0.5rem;
}

.tta-suggestion-list {
	background-color: #fff;
	color: $colour-primary;
	border-radius: 6px;
	overflow: hidden;
	padding: 0.5em 0;
	max-height: 200px;
	overflow-y: auto;
	box-shadow: 0 0 2px rgba(#000, 0.2);

	.client-email, .account-number {
		@include tagLabel();
	}

	li {
		padding: 0.3em 1rem;
	}
}

.tta-editor {
	// Quick copy pasta of the text field input style.
	// This will be cleaned up when the base styles are integrated.
	color: #fff;
	width: 100%;
	min-height: 150px;
    background-color: #30395a;
    border: 1px solid #30395a;
    border-radius: 5px;
    font-size: .9rem;
    padding: .6em .8rem;
	margin-top: .3rem;
	margin-bottom: 2rem;
	outline: none;

	&.focussed {
		background-color: white;
		color: $darkText;
	}
}

.tta-tag {
    height:inherit;
    line-height: inherit;
    padding-left: 0px;
    padding-right: 0px;
	font-weight: 500;
	color: $colour-primary;

	&:hover {
		cursor: pointer;
		opacity: 0.6;
	}
}

.tta-list-selected {
    background-color: rgba($colour-primary, 0.2);
}

//Force _actual_ editor to be full height of the outer
.tta-editor { 
	.DraftEditor-editorContainer {
		>div {
			min-height: 140px;
		}
	}
}