@import "@threeskye/core-components/dist/styles/Variables.scss";

$colour-dropdown-value: $colour-secondary;
$colour-dropdown-selection-list: $colour-secondary;
$colour-dropdown-hover: #424e7b;
$font-size-dropdown: 0.9rem;

@mixin selectionActive {
	height: auto !important;
	padding-top: 5px !important;
	overflow: auto !important;
	opacity: 1 !important;
}

@mixin valueWrapperActive {
	background-color: rgba($colour-dropdown-value, 0.3) !important;

	i {
		transform: rotate(180deg) !important;
	}
}

.dropdown-container {
	min-width: 150px;
	max-width: 200px;
	position: relative;
	outline: none;
	margin-top: 7px;

	&.no-margin-top {
		margin: 0px 10px 0px 0px;
		display: flex;
		justify-content: right;

		.dropdown-value-wrapper {
			background-color: #424E7B;
			height: 24px;
			opacity: 1;
			margin-left: auto;
			justify-content: right;
			padding: 6px;
		}

		&.dd-disabled {
			opacity: 0.5;
		}
	}

	&.dd-disabled {
		pointer-events: none;

		i {
			display: none;
		}

		.dropdown-value-wrapper {
			background-color: rgba($colour-dropdown-value, 0.3);
		}
	}
}

.dropdown-selection__active {
	@include selectionActive();
}

.dropdown-value__active {
	@include valueWrapperActive();
}

.dropdown-value-wrapper,
.dropdown-selection-list {
	padding: 6px 12px;
	border-radius: 5px;
	font-size: $font-size-dropdown;
	cursor: pointer;
	outline: none;
}

.dropdown-value-wrapper {
	transition: 300ms ease-in-out;
	background-color: $colour-dropdown-value;
	justify-content: space-between;

	&:hover {
		box-shadow: 0 0 11px rgba(#000, 0.2);

	}

	i {
		transition: 300ms ease-in-out;
	}

	&.placeholder-value {
		opacity: 0.2;
	}
}

.dropdown-selection-list-container {
	width: 100%;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: height 500ms, opacity 300ms;
	position: absolute;
	z-index: $layer-1;
}

.dropdown-full-width {
	width: 100%;
	max-width: none;
}

.dropdown-selection-list {
	background-color: $colour-dropdown-selection-list;
	padding: 0;
	box-shadow: 0 0 11px rgba(#000, 0.3);
	width: 100%;
	max-height: 250px;
	overflow-y: auto;

	li {
		padding: 11px 12px;
		cursor: pointer;

		&:hover {
			background-color: $colour-dropdown-hover;
		}
	}
}

.fade-out {
	transition: all 300ms ease;
	transform: translateY(-5px);
	opacity: 0;
}

.dropdown-transparent {
	margin-top: 0;

	.dropdown-value-wrapper {
		transition: none;
		background-color: transparent;
		border: none;
		margin: 0;
		padding: 0 2px;

		&:hover {
			box-shadow: none;
			background-color: transparent;
			color: #fff;
		}

		&.dropdown-value__active {
			background-color: transparent !important;
			color: inherit;
		}
	}
}