@import "../../themes/3skye.scss";
@import "../../themes/ColoursRedo.scss";
@import "@threeskye/core-components/dist/styles/Variables.scss";

// ============================
// *** DARK THEME ***
// ============================
.dark-theme {
	.dropdown-menu-wrapper {
		background-color: $colour-secondary;

		ul {
			color: $colour-secondary-contrast;

			li {
				&:hover {
					background-color: rgba($colour-secondary-accent, 0.3);
				}

				&:active {
					background-color: $colour-secondary-light;
				}
			}
		}
	}
}

// ============================
// *** LIGHT THEME ***
// ============================
.light-theme {
	.dropdown-menu-wrapper {
		background-color: $colour-light-light;

		ul {
			color: $colour-light-contrast;

			li {
				&:hover {
					background-color: rgba($colour-light, 0.3);
					color: $colour-light-contrast;
				}

				&:active {
					background-color: $colour-primary;
				}
			}
		}
	}
}

// ============================
// *** STYLES ***
// ============================

.dropdown-menu-wrapper {
	position: absolute;
	top: 110%;
	z-index: $layer-9;
	width: 100%;
	height: 0;
	opacity: 0;
	border-radius: 0.5rem;
	overflow: hidden;
	transition-duration: 500ms;
	box-shadow: 0 0 8px rgba(#000000, 0.3);

	ul {

		li {
			font-size: 1rem;
			padding-bottom: 0.5rem;
			padding: 0.5rem 1rem;
			font-weight: 500;
			transition-duration: 300ms;
			cursor: pointer;

			&:first-child {
				padding-top: 0.9rem;
			}

			&:last-child {
				padding-bottom: 0.9rem;
			}
		}
	}
}
